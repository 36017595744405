<template>
    <div class="w-full h-full flex flex-col overflow-hidden">
        <div class="flex-grow overflow-auto p-6 md:p-12">
            <div 
                v-if="loading == 'fetching'"
                class="h-full flex items-center justify-center">
                
                <div class="spinner"></div>
            </div>
            <div 
                v-else-if="users"
                class="
                    grid
                    gap-6 
                    md:gap-12
                    grid-cols-1
                    lg:grid-cols-2
                    xl:grid-cols-3
                ">
                
                <a 
                    v-for="dU in users"
                    :key="'user_'+dU.username"
                    @click="userEdit = dU"
                    class="flex">
                    
                    <UserCard
                        :userPassed="dU"
                    />
                </a>
            </div>
        </div>
        
        <div class="absolute bottom-0 right-0 m-6">
            <button 
                @click="showUserNew = true"
                class="button submit round">
                
                <i class="fas fa-plus" />
            </button>
        </div>
        
        <Modal 
            v-if="showUserNew"
            @close="showUserNew = false"
            insideClasses="bg-white"
            width="30rem">
            
            <header slot="header">
                Ny bruker
            </header>
            
            <form 
                @submit.prevent="onUserInvite()"
                class="pt-2 px-6 pb-6 overflow-auto flex-grow">
                
                <InputSingleline 
                    v-model="invitation.email"
                    type="email"
                    label="E-postadresse"
                    :required="true"
                />
                
                <InputSingleline 
                    v-model="invitation.given_name"
                    type="text"
                    label="Fornavn"
                    :required="true"
                />
                
                <InputSingleline 
                    v-model="invitation.family_name"
                    type="text"
                    label="Etternavn"
                    :required="true"
                />
				
                <InputSingleline 
                    v-model="invitation.phone_number"
                    type="text"
                    label="Telefonnummer"
					placeholder="+47xxxxxxxx"
                    :required="false"
                />
				
                <!-- <div>
                    <label for="">Språkinnstilling</label>
                    <select 
                        v-model="invitation.locale"
                        class="">
                        
                        <option value="nb">Norsk</option>
                        <option value="en">English</option>
                    </select>
                </div> -->
                
                <div>
                    <label for="">Brukerrettigheter</label>
                    <select 
                        v-model="invitation.role"
                        class="">
                        
                        <option value="admin">Administrator</option>
                        <option value="regular">Normal</option>
                    </select>
                </div>
                
                <div v-if="invitation.role != 'admin'">
                    <label for="">Delta i avdeling(er)</label>
                    <div v-if="departments && departments.length">
                        <div 
                            v-for="department in departments"
                            :key="'department_'+department.departmentId"
                            @click="onToggleDepartment(invitation, department.departmentId)"
                            class="cursor-default flex my-4 items-start">
                            
                            <input 
                                type="checkbox" 
                                :checked="invitation.departments.find( uD => uD == department.departmentId)"
                            />
                            {{department.name}}
                        </div>
                    </div>
                    <em v-else>
                        Ingen avdelinger funnet ennå.
                    </em>
                </div>
                
                <label>
                    <input 
                        type="checkbox" 
                        v-model="invitation.sendEmail"
                    />
                    Send invitasjonen på e-post
                </label>
				
                <label>
                    <input 
                        type="checkbox" 
                        v-model="invitation.sendSms"
                    />
                    Send invitasjonen på SMS
                </label>
                
                
                <label 
                    for="setCustomPassword_false"
                    class="flex items-start">
                    
                    <input 
                        type="radio" 
                        name="setCustomPassword"
                        id="setCustomPassword_false"
                        v-model="invitation.setCustomPassword"
                        :value="false"
                        class="mr-4"
                        @change="invitation.temporaryPassword = null"
                    />
                    
                    
                    Lag engangspassord automatisk
                </label>
                
                
                <label 
                    for="setCustomPassword_true"
                    class="flex items-start mt-0">
                    
                    <input 
                        type="radio" 
                        name="setCustomPassword"
                        id="setCustomPassword_true"
                        v-model="invitation.setCustomPassword"
                        :value="true"
                        class="mr-4"
                        @change="invitation.temporaryPassword = null"
                    />
                    
                    Jeg vil definere engangspassord 
                </label>
                
                <InputSingleline 
                    v-if="invitation.setCustomPassword"
                    v-model="invitation.temporaryPassword"
                    type="text"
                    :required="true"
                />
                
                
                <!-- <hr>
                <pre>{{invitation}}</pre> -->
            </form>
            
            <footer slot="footer">
                <div class="space-x-4 flex">
                    <div class="flex-grow">
                        <a 
                            class="button" 
                            @click="showUserNew = false; showUsersBatchInvite = true">
                            
                            Importér brukere
                        </a>
                    </div>
                    <a 
                        class="button" 
                        @click="showUserNew = false">
                        
                        Avbryt
                    </a>
                    
                    <button 
                        type="submit"
                        @click.prevent="onUserInvite()" 
                        :disabled="loading == 'inviting'"
                        class="button submit"
                        :class="{'loading' : loading == 'inviting'}">
                        
                        Inviter bruker
                    </button>
                </div>
            </footer>
        </Modal>
        
        <UsersBatchInvite 
            v-if="showUsersBatchInvite"
            @close="showUsersBatchInvite = false"
        />
        
        <Modal 
            v-if="showUserWithoutSendEmail"
            @close="showUserWithoutSendEmail = false"
            insideClasses="bg-white"
            width="auto">
            
            <header slot="header">
                Bruker ble invitert
            </header>
            
            <div class="p-6 overflow-auto flex-grow">
                <div v-if="invitationResponse.temporaryPassword">
                    <div class="mb-2">
                        <label>Midlertidig passord</label>
                        <em>
                            Passordet vil kun være synlig frem til du lukker dette vinduet, så pass på å skrive det ned. 
                        </em>
                    </div>
                    
                    <div class="text-6xl text-center bg-beige p-2 rounded border border-beige-dark" style="font-family: mono">
                        {{invitationResponse.temporaryPassword}}
                    </div>
                </div>
                <div v-else>
                    Brukeren du har invitert er allerede tilknyttet en Upsell-konto, og et midlertidig passord er derfor ikke synlig. 
                </div>
            </div>
            
            <footer slot="footer">
                <div class="space-x-4 text-right">
                    <a 
                        class="button" 
                        @click="showUserWithoutSendEmail = false">
                        
                        Lukk
                    </a>
                </div>
            </footer>
        </Modal>
        
        <div 
            v-if="userEdit"
            class="absolute inset-0 flex">
            
            <div 
                @click="userEdit = null"
                class="flex-grow bg-black opacity-25 ">
                
            </div>
            
            <transition name="fade">
                <UserEdit 
                    :userPassed="userEdit" 
                    @close="userEdit = null"
                    class="
                        absolute bg-white right-0 overflow-hidden w-full
                        md:rounded-l-lg 
                        md:w-150
                        md:shadow-2xl
                    "
                />
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            'UserEdit': () => import('@/components/users/UserEdit.vue'),
            'UserCard': () => import('@/components/users/UserCard.vue'),
            'UsersBatchInvite': () => import('@/components/users/UsersBatchInvite.vue'),
        },
        
        computed:{
            users(){
                if ( !this.$store.getters.getUsers || !this.$store.getters.getUsers.length) {
                    return null;
                }
                
                const navigatorLanguage = this.account.locale || 'no';
                
                const users = this.$store.getters.getUsers
                    .map( user => {
                        const clicks = parseInt(localStorage['user_'+user.username] ) || 0;
                        
                        return {
                            ...user,
                            clicks,
                        }
                    })
                    .sort((a, b) => {
                        if (a.given_name && b.given_name) {
                            return a.given_name.localeCompare(b.given_name, navigatorLanguage, { ignorePunctuation: true });
                        }
                    });
                    
                return users; 
            },
            
            departments(){
                return this.$store.getters.getDepartments;
            },
        },
        
        data(){
            return {
                activeSection: 'users',
                userEdit: null,
                showUserNew: false,
                showUserWithoutSendEmail: false,
                loading: null,
                invitation: {
                    role: 'admin',
                    email: null,
                    locale: 'nb',
                    departments: [],
                    given_name: null,
                    family_name: null,
                    sendEmail: true,
                    setCustomPassword: false,
                    temporaryPassword: null,
					phone_number: null,
                    sendSms: true,
                },
                invitationResponse: null,
                
                showUsersBatchInvite: false,
            }
        },
        
        methods: {
			phonenumberIsValid(phonenumber){
				if ( !phonenumber || phonenumber == '') {
					return false;
				}
				
				return phonenumber.startsWith('+');
			},
			
            emailIsValid(email) {
                return /\S+@\S+\.\S+/.test(email)
            },
            
            async onUserInvite(){
                try {
                    this.invitationResponse = null;
                    
                    if (
                        this.invitation.email == '' || 
                        !this.emailIsValid(this.invitation.email) ) {
                        
                        throw Error( 'For å invitere kreves en gyldig e-postadresse' );
                    }
                    
                    if (
                        !this.invitation.family_name || 
                        this.invitation.family_name == '' || 
                        !this.invitation.given_name || 
                        this.invitation.given_name == '' ) {
                        
                        throw Error( 'En bruker må ha fornavn og etternavn' );
                    }
					
                    if (this.invitation.sendSms){
						if ( this.invitation.phone_number != '' && 
							!this.phonenumberIsValid(this.invitation.phone_number) ) {
							
							throw Error( 'Husk å fylle inn telefonnummer med prefix (f.eks. +47)' );
						}
					}
					else {
						// console.log('no sms - no phonenumber needed...');
						delete this.invitation.phone_number;
					}
					
					console.log(this.invitation);
					
					// return
                    
                    this.loading = 'inviting';
                    
                    this.invitationResponse = await this.accountUsersInvite( {
                        invitation: this.invitation,
                        account: this.account,
                    });
                    
                    await this.$store.dispatch('listUsers');
                    
                    if (this.invitation.sendEmail) {
                        this.$notify({ type: 'success', text: 'En invitasjon ble sendt til brukeren.' });
                    }
                    else {
                        this.showUserWithoutSendEmail = true;
                    }
                    
                    this.showUserNew = false;
                    
                    this.invitation = {
                        role: 'admin',
                        email: null,
                        locale: 'nb',
                        departments: [],
                        given_name: null,
                        family_name: null,
                        sendEmail: true,
                        setCustomPassword: false,
                        temporaryPassword: null,
						phone_number: null,
						sendSms: true,
                    };
                } 
                catch (err) {
                    console.log(err);
                    this.$notify( {type: 'error', text: err.message} );
                }
                finally{
                    this.loading = null;
                }
            },
            
            onToggleDepartment( invitation, departmentId ){
                if ( invitation.departments.find( uD => uD == departmentId) ) {
                    invitation.departments = invitation.departments.filter( uD => uD != departmentId );
                }
                else {
                    invitation.departments.push( departmentId );
                }
            },
        },
        
        async mounted(){
            this.loading = 'fetching';
            
            await Promise.allSettled([
                this.$store.dispatch('listUsers'),
                this.$store.dispatch('listDepartments'),
            ]);
            
            this.loading = null;
        },
    }
</script>

<style lang="scss" >

</style>
